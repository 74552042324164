/* @import "normalize.css"; */
.f-roboto {
  font-family: 'Roboto', sans-serif;
}
.f-aleo {
  font-family: 'Aleo', serif;
}
.f-cardo {
  font-family: 'Cardo', serif;
}
.f-cormorant {
  font-family: 'Cormorant', serif;
}
.f-inter {
  font-family: 'Inter', sans-serif;
}
.f-josefin {
  font-family: 'Josefin Sans', sans-serif;
}
.f-lato {
  font-family: 'Lato', sans-serif;
}
.f-merriweather {
  font-family: 'Merriweather', serif;
}
.f-montserrat {
  font-family: 'Montserrat', sans-serif;
}
.f-nunito {
  font-family: 'Nunito', sans-serif;
}
.f-open-sans {
  font-family: 'Open Sans', sans-serif;
}
.f-playfair {
  font-family: 'Playfair Display', serif;
}
.f-pt-sans {
  font-family: 'PT Sans', sans-serif;
}
.f-pt-serif {
  font-family: 'PT Serif', serif;
}
.f-quicksand {
  font-family: 'Quicksand', sans-serif;
}
.f-raleway {
  font-family: 'Raleway', sans-serif;
}
.f-work-sans {
  font-family: 'Work Sans', sans-serif;
}
.f-splash {
  font-family: 'Splash', cursive;
}
.f-tangerine {
  font-family: 'Tangerine', cursive;
}
.f-Be-vietnam {
  font-family: 'Be Vietnam Pro', sans-serif;
}
.f-poppins {
  font-family: 'Poppins', sans-serif;
}
/*
--- 01 TYPOGRAPHY SYSTEM
/*
https://type-scale.com/
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights
Default: 400
Medium: 500
Semi-bold: 600
Bold: 700

- Line heights 
Default: 1
Small: 1.05
Medium: 1.2
Paragraph default: 1.6

- Letter spacing
-0.5px
0.75px

--- 02 COLORS
- Primary:
- Tints:
- Shades: 
- Accents:
- Greys
#767676 (lightest grey allowed on #fff)

/////////////////////////////
Fonts used
Main: Westbake (in transfer)
Sub: Roboto (google font)

Colours in new logo are:
Pink - R:206 G:0 B:126
Green: R58 G156 B53
Orange (used as primary colour at various transparencies): R222 G78 B15
Purple R128 G42 B128
Yellow R230 G175 B0
Blue R0 G123 B172
/////////////////////////////

--- 05 SHADOWS
0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);

--- 06 BORDER-RADIUS
Default: 9px
Medium: 11px

--- 07 WHITESPACE
- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

*/
:root {
  --white: rgb(255, 255, 255);
  --black: #000000;

  --grey-pale: #dddcdc;
  --grey-light: #aaa;
  --grey: #555;
  --grey-dark: #333;

  --primary-pale: #e6f2ff;
  --primary-light: #99ccff;
  /* --primary: #2f5572; */
  --primary-dark: #07294b;

  --primary: #0c171f;
  --secondary-pale: rgb(238, 191, 168);
  --secondary-light: #ee5b11;
  --secondary: rgb(208, 78, 15);
  --secondary-dark: #bf480d;
}
.background-primary-dark,
.bg-primary-dark {
  background: var(--primary-dark);
}
.bg-secondary {
  background: var(--secondary);
}
.color-white {
  color: var(--white);
}
.f-primary {
  font-family: 'Roboto', sans-serif;
}
.f-secondary {
  font-family: 'Westbake', sans-serif;
}
/* //////////////////////////////////////////  */
/* Mixins for break points  */
/* //////////////////////////////////////////  */
/* Flexbox container for grid */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 800px) {
.container {
    /* @media (min-width: 1200px) { */
    flex-direction: row;
    flex-wrap: wrap
}
  }
.halves-box {
  flex: 0 1 49%;
}
.thirds-box {
  flex: 0 1 32%;
  margin: 1rem 2rem;
}
@media (min-width: 800px) {
.thirds-box {
    /* @media (min-width: 1200px) { */
    margin: 0
}
  }
.thirds-box-1 {
  margin: 0;
  flex: 0 1 34%;
}
.thirds-box-2 {
  margin: 0;
  flex: 0 1 64%;
}
.quaters-box {
  flex: 0 1 23%;
  margin: 2rem 0rem;
  /* &:last-child {
    margin-right: 0;
  } */
}
@media (min-width: 800px) {
.quaters-box {
    /* @media (min-width: 1200px) { */
    margin: 0
}
  }
/* ////////////////////////////  */
/* Pattern styles */
/* Not for general usage */
.col-container {
  display: flex;
  flex-direction: column;

  /* gap will be show vertically */
  /* use margin instead below to stop this */
}
/* gap: 2rem; */
@media (min-width: 530px) {
.col-container {
    /* @media (min-width: 620px) {  */
    flex-direction: row
}
  }
.col-container--center-content > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
.left-half {
  background-color: #ff9e2c;
  flex: 1 1 auto;
  padding: 1rem;
}
@media (min-width: 530px) {
.left-half {
    /* @media (min-width: 620px) {  */
    /* mentioned above */
    margin-right: 0.75rem
}
  }
.right-half {
  background-color: #b6701e;
  flex: 1 1 auto;
  padding: 1rem;
}
@media (min-width: 530px) {
.right-half {
    /* @media (min-width: 620px) {  */
    /* mentioned above */
    margin-left: 0.75rem
}
  }
/* /////////////////////  */
/*  50-50  grid container */
.col-container-grid {
  display: grid;
}
/* gap: 1rem; */
.col-container-grid__left-half {
    background: #008b8b;
  }
/* grid-column: auto;  use unset ot auto*/
@media (min-width: 530px) {
.col-container-grid__left-half {
    /* @media (min-width: 620px) {  */
      grid-column: 1;
  }
  }
.col-container-grid__left-half {
    margin-right: 0.75rem;
}
.col-container-grid__right-half {
    background: #556b2f;
  }
/* grid-column: unset; */
@media (min-width: 530px) {
.col-container-grid__right-half {
    /* @media (min-width: 620px) {  */
      grid-column: 2;
  }
  }
.col-container-grid__right-half {
    margin-left: 0.75rem;
}
.col-container-grid__article {
    position: relative;
    top: 50%;
    text-align: center;
    transform: translate(0, -50%);
    padding: 1rem;
  }
/* ////////////////////////////////////////////////////  */
/* provide responsive padding for each section in a page*/
/* ////////////////////////////////////////////////////  */
.wrapper {
  overflow: hidden;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  max-width: 1236px;
  /* margin-left: auto;
  margin-right: auto; */
  margin-inline: auto;
}
.wrapper--large {
    max-width: 1400px;
  }
.wrapper--medium {
    max-width: 976px;
  }
.wrapper--narrow,
  .wrapper--small {
    max-width: 470px;
  }
.wrapper--full,
  .wrapper--xlarge {
    max-width: none;
  }
.wrapper--no-padding-until-small {
    padding-left: 0;
    padding-right: 0;
  }
@media (min-width: 530px) {
.wrapper--no-padding-until-small {
    /* @media (min-width: 620px) {  */
      padding-left: 18px;
      padding-right: 18px
  }
  }
.wrapper--no-padding-until-medium {
    padding-left: 0;
    padding-right: 0;
  }
@media (min-width: 800px) {
.wrapper--no-padding-until-medium {
    /* @media (min-width: 1200px) { */
      padding-left: 18px;
      padding-right: 18px
  }
  }
.wrapper--no-padding-until-large {
    padding-left: 0;
    padding-right: 0;
  }
@media (min-width: 1250px) {
.wrapper--no-padding-until-large {
      padding-left: 18px;
      padding-right: 18px
  }
  }
.wrapper .wrapper {
    /* Any wrapper nested in another wrapper to have no padding  */
    padding-left: 0;
    padding-right: 0;
  }
.wrapper--b-margin {
    margin-bottom: 1.5rem;
  }
@media (min-width: 530px) {
.wrapper--b-margin {
    /* @media (min-width: 620px) {  */
      margin-bottom: 3.875rem
  }
  }
/* /////////////////////////////////////////// */
/* Blog grid wrapper - RJ Militaria articles  */
.blog-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 5%;
}
.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  grid-gap: 20px;
}
.blog-grid__box {
  border: 1px solid var(--primary);
  padding: 1rem;
  margin: 1rem;
  display: grid;
  align-items: center;
  justify-content: center;
}
.bg--dark {
  background-color: var(--dark);
}
/* //////////////////////////////////////////// */
/*  Add top and bottom padding for each section */
.page-section {
  padding-top: 4.6rem;
  padding-bottom: 4.6rem;
}
@media (min-width: 800px) {
.page-section {
    /* @media (min-width: 1200px) { */
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
}
  }
.page-section--more-b-margin {
    margin-bottom: 7rem;
  }
@media (min-width: 1250px) {
.page-section--more-b-margin {
      padding-bottom: 9rem !important
  }
  }
.page-section--no-b-padding-until-medium {
    padding-bottom: 0;
  }
@media (min-width: 800px) {
.page-section--no-b-padding-until-medium {
    /* @media (min-width: 1200px) { */
      padding-bottom: 4.5rem
  }
  }
.page-section--no-b-padding-until-large {
    padding-bottom: 0;
  }
@media (min-width: 1250px) {
.page-section--no-b-padding-until-large {
      padding-bottom: 4.5rem
  }
  }
.page-section--after-skew {
    padding: 0;
    margin-top: -10rem;
  }
@media (min-width: 800px) {
.page-section--after-skew {
    /* @media (min-width: 1200px) { */
      margin-top: 0
  }
  }
.page-section--blue {
    background-color: var(--primary);
    color: #fff;
  }
.page-section--testimonials {
    background-color: #e0e6ef;
  }
@media (min-width: 530px) {
.page-section--testimonials {
    /* @media (min-width: 620px) {  */
      background: url('assets/images/testimonials-bg.jpg') top center no-repeat;
      background-size: cover
  }
  }
.page-section--vis-testimonials {
    background-color: #e0e6ef;
  }
@media (min-width: 530px) {
.page-section--vis-testimonials {
    /* @media (min-width: 620px) {  */
      background: url('assets/images/portfolio/country-2.jpg') top center
        no-repeat;
      background-size: cover
  }
  }
/* //////////////////////////////////////////// */
/*  Use for text boxes inside a grid */
/* //////////////////////////////////////////// */
.generic-content-container p {
    font-weight: 300;
    line-height: 1.65;
    margin: 0 0 1.8rem 0;
  }
@media (min-width: 530px) {
.generic-content-container p {
    /* @media (min-width: 620px) {  */
      font-size: 1.65rem
  }
  }
.generic-content-container p a {
    font-weight: 700;
    color: var(--secondary);
  }
/* ////////////////////////// */
/*  picture gallery options   */
/* ///////////////////////// */
.r-hero {
  border-bottom: 10px solid var(--primary);
  /* position: relative; */
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.r-hero__image {
    opacity: 0.5;
    grid-column: 1 / 3;
    grid-row: 1;
  }
.r-hero__image img {
      display: block;
      width: 100%;
    }
.r-hero__text-content-c {
    grid-column: 1 / 3;
    grid-row: 1;
    align-self: center;
    padding-top: 60px;
    text-align: center;
    /* position: absolute; top: 50%;  transform: translateY(-50%);  left: 0;   width: 100%; */
  }
.r-hero__text-content-l {
    grid-column: 1 / 2;
    grid-row: 1;
    align-self: center;
    padding-top: 60px;
    text-align: center;
  }
.r-hero__text-content-r {
    grid-column: 2 / 3;
    grid-row: 1;
    align-self: center;
    padding-top: 60px;
    text-align: center;
  }
.r-hero__title {
    font-weight: 300;
    color: var(--primary);
    margin: 0;
    font-size: 4.2rem;
  }
.r-hero__subtitle {
    font-weight: 300;
    color: var(--primary) !important;
    margin: 0;
    font-size: 3.2rem !important;
  }
.r-hero__description {
    font-weight: 300;
    color: var(--primary);
    font-size: 2.4rem;
    margin: 0;
  }
.r-hero__subtitle {
    color: #fff;
    font-size: 2.1rem;
    font-weight: 100;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    max-width: 42rem;
    margin: 0 auto;
  }
.r-hero__description--text-main-color {
    color: var(--primary);
  }
.abs-hero {
  border-bottom: 10px solid var(--primary);
  position: relative;
}
.abs-hero__image {
    display: block;
    /* opacity: 0.2; */
  }
.abs-hero__text-content {
    padding-top: 60px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    text-align: center;
  }
.abs-hero__title {
    font-weight: 300;
    color: var(--primary);
    margin: 0;
    font-size: 2.4rem;
  }
@media (min-width: 530px) {
.abs-hero__title {
    /* @media (min-width: 620px) {  */
      font-size: 4.8rem
  }
  }
.abs-hero__subtitle {
    font-weight: 300;
    color: var(--primary);
    font-size: 1.5rem;
    margin: 0;
  }
@media (min-width: 530px) {
.abs-hero__subtitle {
    /* @media (min-width: 620px) {  */
      font-size: 2.9rem
  }
  }
.abs-hero__description {
    color: #fff;
    font-size: 1.1rem;
    font-weight: 100;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }
@media (min-width: 530px) {
.abs-hero__description {
    /* @media (min-width: 620px) {  */
      font-size: 1.875rem
  }
  }
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}
@media only screen and (max-width: 600px) {
  html {
    font-size: 56%;
  }
}
@media only screen and (min-width: 1200px) {
  html {
    font-size: 72%;
  }
}
/* /////////////////
/* Webfont: Glowstore-Regular */
@font-face {
  font-family: 'Glowstore';
  src: url('./assets/fonts/Glowstore.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/Glowstore.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/Glowstore.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/Glowstore.woff2') format('woff2'),
    /* Modern Browsers */ url('./assets/fonts/Glowstore.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Oliver';
  src: url('./assets/fonts/Oliver-Thin.eot');
  src: local('./assets/fonts/Oliver Thin'), local('Oliver-Thin'),
    url('./assets/fonts/Oliver-Thin.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Oliver-Thin.woff2') format('woff2'),
    url('./assets/fonts/Oliver-Thin.woff') format('woff'),
    url('./assets/fonts/Oliver-Thin.ttf') format('truetype'),
    url('./assets/fonts/Oliver-Thin.svg#Oliver-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}
/* Webfont: Westbake-Regular */
@font-face {
  font-family: 'Westbake';
  src: url('./assets/fonts/westbake/Web-TT/Westbake.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/westbake/Web-TT/Westbake.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/westbake/Web-TT/Westbake.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/westbake/Web-TT/Westbake.woff2')
      format('woff2'),
    /* Modern Browsers */ url('./assets/fonts/westbake/Web-TT/Westbake.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
body {
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Oliver', sans-serif; */
  /* font-family: 'Glowstore', sans-serif; */
  /* font-family: 'Westbake', sans-serif; */
  line-height: 1;
  font-weight: 400;
  color: #333;
  overflow-x: hidden; /*added for omni menu */
}
h1 {
  font-size: 4rem;
  line-height: 1.4;
  font-weight: 300;
  padding: 1rem 0;
}
h2 {
  font-size: 3.6rem;
  line-height: 1.1;
  font-weight: 300;
  padding: 1rem 0;
}
h3 {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 300;
  padding: 1rem 0;
}
h4 {
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: 300;
  padding: 1rem 0;
}
h5 {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 300;
  padding: 1rem 0;
}
h6 {
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 300;
  padding: 1rem 0;
}
p {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.6;
  padding: 0.5em 0;
}
a {
  color: var(--primary);
  font-weight: normal;
  text-decoration: none;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}
dl {
  font-size: 1.8rem;
  line-height: 1.6;
  padding: 0.5em 0;
}
ol li {
  /* list-style-type: decimal; or 'decimal-leading-zero', 'upper-alpha', 'lower-alpha'... */
  list-style-position: outside; /* or 'inside' */
  margin-left: 1em;
  line-height: 1.6;
}
ul li {
  /* list-style-type: decimal; or 'decimal-leading-zero', 'upper-alpha', 'lower-alpha'... */
  list-style-position: outside; /* or 'inside' */
  margin-left: 1em; /* gives 'room' for the numbers to appear in */
  line-height: 1.6;
}
label {
  font-size: 1.5rem;
}
::-moz-selection {
  /* background-color: $color-primary; */
  background-color: var(--primary);
  color: var(--white);
}
::selection {
  /* background-color: $color-primary; */
  background-color: var(--primary);
  color: var(--white);
}
/* //////////////////////////////////// */
/*               Utility                */
/* //////////////////////////////////// */
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.center-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 530px) {
.center-row {
    /* @media (min-width: 620px) {  */
    font-size: 2.1rem;
    flex-direction: row;
    gap: 2rem
}
  }
.center-block {
  margin: 0 auto !important;
}
.center-image {
  display: block !important;
  margin: 0 auto !important;
  /* margin-left: auto; */
  /* margin-right: auto; */
}
.image-100-per,
.img-100,
.img-100p {
  width: 100%;
}
.center-text {
  text-align: center;
}
/* .hide {display: none; } */
.hide {
  opacity: 0;
  visibility: hidden;
}
.logos {
  display: flex;
}
.caps {
  text-transform: uppercase;
}
.no-pad {
  padding: 0 !important;
}
.pad--small {
  padding: 1.5rem !important;
}
.padb--small {
  padding-bottom: 1.5rem !important;
}
.padt--small {
  padding-top: 1.5rem !important;
}
.pads--small {
  padding: 0 1.5rem !important;
}
.pad--med {
  padding: 4rem !important;
}
.padb--medium {
  padding-bottom: 4rem !important;
}
.padt--medium {
  padding-top: 4rem !important;
}
.pads--medium {
  padding: 0 4rem !important;
}
.pad--large {
  padding: 8rem !important;
}
.pads--large {
  padding: 8rem !important;
}
.padb--large {
  padding-bottom: 8rem !important;
}
.padt--large {
  padding-top: 8rem !important;
}
.pad--xlarge {
  padding: 10rem 0 !important;
}
.padtb--large {
  padding: 8rem 0 !important;
}
.padtb--xlarge {
  padding: 10rem 0 !important;
}
.padt--xlarge {
  padding-top: 10rem !important;
}
.no-margin {
  margin: 0;
}
.margin--small {
  margin: 2rem !important;
}
.marginb--small {
  margin-bottom: 2rem !important;
}
.margint--small {
  margin-top: 2rem !important;
}
.margin--medium {
  margin: 4rem !important;
}
.marginb--medium {
  margin-bottom: 4rem !important;
}
.margint--medium {
  margin-top: 4rem !important;
}
.margin--large {
  margin: 8rem !important;
}
.marginb--large {
  margin-bottom: 8rem !important;
}
.margint--large {
  margin-top: 8rem !important;
}
.margint--super {
  margin-top: 15rem !important;
}
.t--thin {
  font-weight: 100;
}
.t--medium {
  font-weight: 400;
}
.t--thick {
  font-weight: 700;
}
.italic {
  font-style: italic !important;
}
.tp--small {
  font-size: 1.5rem;
}
.tp--medium {
  font-size: 1.8rem;
}
.tp--large {
  font-size: 2.1rem;
}
.tp--xlarge {
  font-size: 2.4rem;
}
.banner {
  font-size: 5rem;
  line-height: 1;
}
/* .headline {  margin: 0;  font-family: 'Roboto Condensed', sans-serif;  font-weight: 300; }
.headline--medium {  font-size: 3.5rem;  margin-bottom: .75rem; }
.headline--small {    font-size: 3.0rem;    margin-bottom: .75rem; } */
.subhead::first-letter {
  font-size: 150%;
  color: var(--primary);
}
.border-black {
  border: var(--black);
}
.border-white {
  border: var(--white);
}
.border-grey {
  border: var(--grey);
}
.border-primary {
  border: var(--primary);
}
.border-secondary {
  border: var(--secondary);
}
.border-tertiary {
  border: var(--tertiary);
}
.border-highlight {
  border: var(--highlight);
}
.border-fine {
  border: 1px solid;
}
.border-thin {
  border: 2px solid;
}
.border-medium {
  border: 3px solid;
}
.border-thick {
  border: 4px solid;
}
blockquote {
  font-style: italic;
  padding: 0.5em 0;
}
/* **************** */
/* Horizontal Rules */
hr.black {
  border: 1.5px solid var(--black);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}
hr.white {
  border: 1.5px solid var(--white);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}
hr.grey {
  border: 1.5px solid var(--grey);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}
hr.primary {
  border: 1.5px solid var(--primary);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}
hr.secondary {
  border: 1.5px solid var(--secondary);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}
hr.tertiary {
  border: 1.5px solid var(--tertiary);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}
hr.highlight {
  border: 1.5px solid var(--highlight);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}
/* //////////////////////////////////// */
/*               buttons                */
/* //////////////////////////////////// */
.btn {
  display: inline-block; /*   as this has padding  */
  text-decoration: none;
  margin-top: 3.2px;
  border-radius: 4px;
  overflow: hidden;
  text-decoration: none;
  color: var(--white);
  font-size: 1.7rem;
  padding: 0.8rem 1.2rem;
  border: none;
  outline: none;
  background: var(--primary);
}
.btn--orange {
    background: var(--secondary);
  }
.btn--orange:hover {
      background: var(--secondary-dark) !important;
    }
.btn--red {
    background: #b94141;
  }
.btn--red:hover {
      background: #be1212 !important;
    }
.btn--large {
    font-size: 1.8rem;
    padding: 1.4rem 1.2rem;
  }
/* padding: 1.1rem 1.9rem; */
@media (min-width: 530px) {
.btn--large {
    /* @media (min-width: 620px) {  */
      font-size: 2.1rem
      /* font-size: 1.25rem; */
  }
  }
.btn:hover,
.btn:active {
  background-color: var(--primary-dark);
}
.btn--primary {
  background-color: var(--primary);
}
/* ********************************** */
.btn-1,
  .btn-1:link,
  .btn-1:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.5rem 4rem;
    display: inline-block;
    border-radius: 10rem;
    transition: all 0.2s;
    position: relative;
    border: none;
    cursor: pointer;
  }
.btn-1:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }
/* .btn:hover::after  - increase the size of the pseudo element*/
.btn-1:hover::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
.btn-1:active,
  .btn-1:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(var(--black), 0.2);
  }
.btn-1--white {
    background-color: var(--white);
    color: var(--grey-dark);
  }
.btn-1--white::after {
      background-color: var(--white);
    }
.btn-1--green {
    background-color: var(--primary);
    color: var(--white);
  }
.btn-1--green::after {
      background-color: var(--primary);
    }
.btn-1::after {
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }
.btn-1--animated {
    -webkit-animation: moveInBottom 0.5s ease-out 0.75s;
            animation: moveInBottom 0.5s ease-out 0.75s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
  }
.btn-1-text:link,
  .btn-1-text:visited {
    color: var(--primary);
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid var(--primary);
    padding: 0.5rem;
    transition: all 0.2s;
  }
.btn-1-text:hover {
    background-color: var(--primary);
    color: var(--white);
    box-shadow: 0 1rem 2rem rgba(var(--black), 0.15);
    transform: translateY(-2px);
  }
.btn-1-text:active {
    box-shadow: 0 0.5rem 1rem rgba(var(--black), 0.15);
    transform: translateY(0);
  }
/* //////////////////////////////////// */
/*               icons                  */
/* //////////////////////////////////// */
.icon {
  height: 6.5rem;
  width: 6.5rem;
}
.icon--orange {
    fill: var(--secondary);
  }
/* //////////////////////////////////// */
/*         social media icons           */
/* //////////////////////////////////// */
.social-icons {
  text-align: center;
}
.social-icons__icon {
    background-color: var(--secondary);
    display: inline-block;
    width: 33%;
    height: 72px;
    margin: 0 5px 5px 5px;
    position: relative;
  }
@media (min-width: 530px) {
.social-icons__icon {
    /* @media (min-width: 620px) {  */
      width: 72px;
  }
  }
.social-icons__icon img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
/* //////////////////////////////////// */
/*               Video                  */
/* //////////////////////////////////// */
.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.3;
  overflow: hidden;
}
.bg-video__content {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
/* //////////////////////////////////// */
/*               Animations             */
/* //////////////////////////////////// */
@-webkit-keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@-webkit-keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@-webkit-keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
/* ///////////////////   */
.gallery {
  display: grid;
}
.gallery__item {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover; /* this must gave the width and height set  */
    display: block;
  }
.gallery__img {
    width: 100%;
  }
.gallery > * {
  }
/* @import 'modules/_large-hero'; */
/* @import 'modules/_page-section'; */
.headline {
  font-family: 'Roboto Condensed', sans-serif;
  /* font-family: $default-font-family; */
  font-size: 3.5rem;
  font-weight: 300;
  /* color: $mainBlue; */
}
/* margin: 2rem 0; */
@media (min-width: 530px) {
.headline {
    /* @media (min-width: 620px) {  */
    font-size: 4.5rem;
}
  }
.headline--centered {
    text-align: center;
  }
.headline--orange {
    color: var(--secondary);
  }
.headline--small {
    font-size: 2.2rem;
  }
@media (min-width: 530px) {
.headline--small {
    /* @media (min-width: 620px) {  */
      font-size: 3rem
  }
  }
.headline--narrow {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }
@media (min-width: 530px) {
.headline--narrow {
    /* @media (min-width: 620px) {  */
      max-width: 500px
  }
  }
.headline--medium {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
@media (min-width: 530px) {
.headline--medium {
    /* @media (min-width: 620px) {  */
      max-width: 700px
  }
  }
@media (min-width: 800px) {
.headline--medium {
    /* @media (min-width: 1200px) { */
      max-width: 800px
  }
  }
.headline--light {
    font-weight: 100;
  }
.headline strong {
    font-weight: 400;
  }
.headline--b-margin-small {
    margin-bottom: 0.6em;
  }
.headline--b-margin-large {
    margin-bottom: 1.8em;
  }
.headline--no-t-margin {
    margin-top: 0;
  }
.row::after {
    content: '';
    clear: both;
    display: table;
  }
.row--t-padding {
    padding-top: 80px;
  }
.row--gutters {
    margin-right: -65px;
  }
.row--gutters-large {
    margin-right: -100px;
  }
.row--gutters-small {
    margin-right: -45px;
  }
.row--gutters > div {
    padding-right: 65px;
  }
.row--gutters-large > div {
    padding-right: 100px;
  }
.row--gutters-small > div {
    padding-right: 45px;
  }
.row__b-margin-until-medium {
    margin-bottom: 1rem;
  }
@media (min-width: 800px) {
.row {
    /* @media (min-width: 1200px) { */
  }

    .row__b-margin-until-medium {
      margin-bottom: 0;
    }

    .row__medium-4 {
      float: left;
      width: 33.33%;
    }

    .row__medium-4--larger {
      width: 37%;
    }

    .row__medium-6 {
      float: left;
      width: 50%;
    }

    .row__medium-8 {
      float: left;
      width: 66.66%;
    }

    .row__medium-8--smaller {
      width: 63%;
    }
  }
@media (min-width: 1250px) {

    .row__b-margin-until-large {
      margin-bottom: 0;
    }

    .row__large-4 {
      float: left;
      width: 33.33%;
    }

    .row__large-4--larger {
      width: 37%;
    }

    .row__large-6 {
      float: left;
      width: 50%;
    }

    .row__large-8 {
      float: left;
      width: 66.66%;
    }

    .row__large-8--smaller {
      width: 63%;
    }
  }
/* Begin Equal Height Rules */
@media (min-width: 800px) {
.row--equal-height-at-medium {
    /* @media (min-width: 1200px) { */
      display: flex;

  }
  }
.row--equal-height-at-medium > div {
      float: none;
      display: flex;
    }
@media (min-width: 1250px) {
.row--equal-height-at-large {
      display: flex;

  }
  }
.row--equal-height-at-large > div {
      float: none;
      display: flex;
    }
.section-title {
  font-size: 4rem;
  font-weight: 300;
  text-align: center;
  padding: 4rem 0 5rem 0;
}
@media (min-width: 530px) {
.section-title {
    /* @media (min-width: 620px) {  */
    font-size: 5rem;
}
  }
.section-title strong {
    font-weight: 500;
  }
.section-title--gradient {
    background-image: linear-gradient(
      to right,
      var(--primary-light),
      var(--primary-dark)
    );
    -webkit-background-clip: text;
    color: transparent;
  }
.section-title--blue {
    color: var(--primary);
  }
.section-title--gob-blue {
    color: var(--primary);
  }
.section-title--less-margin {
    margin-bottom: 1.5rem;
    margin-top: 0;
  }
.section-title--more-margin {
    margin-bottom: 6rem;
  }
@media (min-width: 530px) {
.section-title--more-margin {
    /* @media (min-width: 620px) {  */
      margin-bottom: 8rem
  }
  }
.section-title__icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.6rem;
  }
@media (min-width: 530px) {
.section-title__icon {
    /* @media (min-width: 620px) {  */
      position: relative;
      top: 8px;
      margin-right: 0.5rem;
      margin-left: 0;
      margin-bottom: 0;
      display: inline-block
  }
  }
.feature-item {
  position: relative;
  padding-bottom: .5rem;
}
@media (min-width: 530px) {
.feature-item {
    /* @media (min-width: 620px) {  */
    padding-left: 94px;
}
  }
.feature-item__icon {
    display: block;
    margin: 0 auto;
  }
@media (min-width: 530px) {
.feature-item__icon {
    /* @media (min-width: 620px) {  */
      position: absolute;
      left: 0
  }
  }
.feature-item__title {
    font-size: 2.7rem;
    font-weight: 300;
    margin-top: .7em;
    margin-bottom: .7rem;
    text-align: center;
  }
@media (min-width: 530px) {
.feature-item__title {
    /* @media (min-width: 620px) {  */
      text-align: left;
      margin-top: .7em;
      font-size: 3rem
  }
  }
.testimonial {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 0 18px 1px 18px;
  margin-bottom: 98px;
}
@media (min-width: 1250px) {
.testimonial {
    margin-bottom: 0;
    padding: 0 1.8125rem 1px 1.8125rem;
}
  }
.testimonial--last {
    margin-bottom: 0;
  }
.testimonial__photo {
    border-radius: 80px;
    overflow: hidden;
    width: 160px;
    height: 160px;
    margin: 2rem auto -80px auto;
    position: relative;
    top: -80px;
    border: 6px solid rgba(255, 255, 255, 0.8);
  }
.testimonial__title {
    color: var(--primary);
    text-align: center;
    font-size: 2.4rem;
    font-weight: 500;
    margin: 0;
  }
.testimonial__subtitle {
    color: var(--secondary);
    text-align: center;
    font-weight: 400;
    font-size: 1.5rem;
    margin: 0.3rem 0 1.2rem 0;
  }
.testimonial__para {
    text-align: center;
    font-weight: 400;
    font-size: 2rem;
  }
.site-footer {
  background-color: var(--primary);
  color: #fff;
  padding: 1rem 0;
  text-align: center;
}
.site-footer__text {
    display: block;
    margin-bottom: 1rem;
    font-weight: 200;
  }
@media (min-width: 800px) {
.site-footer__text {
    /* @media (min-width: 1200px) { */
      display: inline;
      margin-bottom: 0;
      margin-right: 1.4rem
  }
  }
.site-footer .btn {
    display: inline;
  }
/* @import 'modules/_site-header'; */
/* @import 'modules/_primary-nav'; */
.reveal-item{
    opacity: 0;
    transition: opacity 1.5s ease-out, transform 1.5s ease-out;
    transform: scale(1.15);
}
.reveal-item--is-visible{
        opacity: 1;
        transform: scale(1);
    }
/* get in touch modal */
.modal {
  opacity: 0;
  visibility: hidden;
  transform: scale(1.2);
  transition: all 0.3s ease-out;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
  z-index: 5;
  display: flex;
}
.modal--is-visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
.modal__inner {
    flex: 1 1 auto;
    margin: auto;
  }
.modal__description {
    text-align: center;
    font-size: 2.1rem;
    font-weight: 300;
    line-height: 1.65;
  }
.modal__close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 3rem;
    color: var(--primary);
    transform: scaleX(1.2);
    transform-origin: 100 0;
    cursor: pointer;
  }
.modal__close:hover {
      color: var(--secondary);
    }
/* prevent scroll on modal open */
.body-no-scroll {
  overflow: hidden;
}
/* /////////////////////////////////////////////  */
/* The Content Modals  */
.modal-generic {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  padding-top: 10px; /* Location of the box */
  padding-bottom: 10px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto;
}
/* Enable scroll if needed */
@media (min-width: 530px) {
.modal-generic {
    /* @media (min-width: 620px) {  */
    padding-top: 60px; /* Location of the box */
    padding-bottom: 30px /* Location of the box */
}
  }
/*  Modal1 (background) */
.modal-1 {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9);
}
/* Black w/ opacity */
.modal-1--is-visible {
    opacity: 1;
    visibility: visible;
    display: block;
    transform: scale(1);
  }
/* modal type 2  */
.modal-2 {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
  transform: scale(1.2);
  transition: all 0.3s ease-out;
  display: block;
  opacity: 0;
  visibility: hidden;
  transform: scale(1.2);
  transition: all 0.3s ease-out;
}
.modal-2--is-visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
/* modal type 3  */
.modal-3 {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
  transform: scale(1.2);
  transition: all 0.3s ease-out;
  display: block;
  opacity: 0;
  visibility: hidden;
  transform: scale(1.2);
  transition: all 0.3s ease-out;
}
.modal-3--is-visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
/* modal type 4  */
.modal-4 {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
}
/* Black w/ opacity */
.modal-4--is-visible {
    opacity: 1;
    visibility: visible;
    display: block;
    transform: scale(1);
  }
/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid rgb(0, 0, 0);
  width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}
@media (min-width: 530px) {
.modal-content {
    /* @media (min-width: 620px) {  */
    width: 80%
}
  }
/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
/* The Close Button */
/* .close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  } */
.close {
  position: absolute;
  top: -2px;
  right: 15px;
  font-size: 5rem;
  color: white;
  transform: scaleX(1.2);
  transform-origin: 100 0;
  cursor: pointer;
}
.close:hover {
    color: var(--secondary);
  }
.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}
.modal-header-2 {
  padding: 2px 16px;
  background-color: #b5c4bb;
  color: white;
}
.modal-header-3 {
  padding: 2px 16px;
  background-color: #a0886e;
  color: white;
}
.modal-header-4 {
  padding: 2px 16px;
  background-color: #c6dd40;
  color: white;
}
.top1 {
  grid-area: top1;
}
.top2 {
  grid-area: top2;
}
.mid1 {
  grid-area: mid1;
}
.mid2 {
  grid-area: mid2;
}
.bot1 {
  grid-area: bot1;
}
.bot2 {
  grid-area: bot2;
}
.modal-body {
  /* default padding for modal */
  padding: 1rem 1.6rem;
}
.modal-body--grid-1 {
    /*  padding for grid modal */
    /* padding: 1rem 1rem; */
    padding: 1rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    /* background-color: #97a596; */
    grid-template-areas:
      'top1 top1'
      'top2 top2'
      'mid1 mid1'
      'mid2 mid2'
      'bot1 bot2';
  }
@media (min-width: 530px) {
.modal-body--grid-1 {
    /* @media (min-width: 620px) {  */
      /* grid-template-columns: 1fr 1fr; */
      /* padding:  0 4rem 1rem 4rem; */
  }
  }
@media (min-width: 800px) {
.modal-body--grid-1 {
    /* @media (min-width: 1200px) { */
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        'top1 top1 top2 top2'
        'mid1 mid1 mid2 mid2'
        'bot1 bot2 mid2 mid2';
  }
  }
.modal-body--grid-1--no-margin {
      grid-gap: 0;
    }
.modal-body--grid-1 > * {
    background-color: #97a596;
    padding: 1rem 1rem;
  }
.modal-body--grid-2 {
    /*  padding for grid modal */
    /* padding: 1rem 1rem; */
    padding: 1rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    /* background-color: #97a596; */
    grid-template-areas:
      'top1 top1'
      'top2 top2'
      'mid1 mid1'
      'mid2 mid2'
      'bot1 bot2';
  }
@media (min-width: 530px) {
.modal-body--grid-2 {
    /* @media (min-width: 620px) {  */
      /* grid-template-columns: 1fr 1fr; */
      /* padding:  0 4rem 1rem 4rem; */
  }
  }
@media (min-width: 800px) {
.modal-body--grid-2 {
    /* @media (min-width: 1200px) { */
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        'top1 top1 top2 top2'
        'mid2 mid2 mid1 mid1'
        'mid2 mid2 bot1 bot2';
  }
  }
.modal-body--grid-2--no-margin {
      grid-gap: 0;
    }
.modal-body--grid-2 > * {
    background-color: #b5c4bb;
    padding: 1rem 1rem;
  }
.modal-body--grid-3 {
    /*  padding for grid modal */
    /* padding: 1rem 1rem; */
    padding: 1rem 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    /* background-color: #97a596; */
    grid-template-areas:
      'top1'
      'mid1'
      'mid2'
      'bot1'
      'bot2';
  }
@media (min-width: 530px) {
.modal-body--grid-3 {
    /* @media (min-width: 620px) {  */
      /* grid-template-columns: 1fr 1fr; */
      /* padding:  0 4rem 1rem 4rem; */
  }
  }
@media (min-width: 800px) {
.modal-body--grid-3 {
    /* @media (min-width: 1200px) { */
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        'top1 top1 top1'
        'mid1 mid1 mid1'
        'mid2 bot1 bot2';
  }
  }
.modal-body--grid-3--no-margin {
      grid-gap: 0;
    }
.modal-body--image {
    width: 100%;
    min-width: 100%;
  }
.modal-body--grid-3 > * {
    background-color: #b5c4bb;
    padding: 1rem 1rem;
  }
.modal-footer {
  padding: 2px 16px;
  background-color: #97a596;
  color: white;
}
/* @import 'modules/_client-area';
@import '../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css';
@import 'modules/_visible-components'; */
:root {
  --secondary: rgb(208, 78, 15);
  --secondary-dark: rgb(172, 75, 27);
  --white: #fff;
}
.about__blue {
  /* background-color: #0c171f; */

  background: linear-gradient(100deg, #115361, #0f0f0f);
  /* background: linear-gradient(100deg, rgb(138, 67, 32), #e05109); */
  /* background: linear-gradient(100deg, #fa5243, #eba349); */
}
.about-vis {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
@media (min-width: 800px) {
.about-vis {
    /* @media (min-width: 1200px) { */
    flex-direction: row;
    gap: 2rem
}
  }
.about-vis__inner {
  flex: 1 0 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about-vis_img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: left;
     object-position: left;
}
@media (min-width: 530px) {
.about-vis_img {
    /* @media (min-width: 620px) {  */
    height: 100%
}
  }
.title-pad {
  padding: 4rem 0 !important;
}
@media (min-width: 530px) {
.title-pad {
    /* @media (min-width: 620px) {  */
    /* font-size: 2.4rem !important; */
    padding: 3rem 0 !important
}
  }
.title--sm {
  font-size: 2.4rem !important;
  line-height: 1.22;
  letter-spacing: -0.75px;
}
.intro {
  font-size: 2.2rem !important;
  line-height: 1.45;
}
@media (min-width: 800px) {
.intro {
    /* @media (min-width: 1200px) { */
    font-size: 2rem !important
}
  }
/* Visible feature item */
.vis-feature-item {
  position: relative;
  /* padding-bottom: 0.5rem; */
}
/* left: 5rem; */
@media (min-width: 530px) {
.vis-feature-item {
    /* @media (min-width: 620px) {  */
    /* padding-left: 94px; */
    /* left: 8.5rem;
    top: 200px; */
}
  }
.vis-feature-item__icon {
    display: block;
    margin: 0 auto;
  }
@media (min-width: 530px) {
.vis-feature-item__icon {
    /* @media (min-width: 620px) {  */
      position: absolute;
      left: 10rem;
      top: 7.5rem
  }
  }
@media (min-width: 800px) {
.vis-feature-item__icon {
    /* @media (min-width: 1200px) { */
      left: 4rem
  }
  }
@media (min-width: 1250px) {
.vis-feature-item__icon {
      left: 7rem
  }
  }
.vis-icon {
  height: 8.5rem;
  width: 8.5rem;
}
.vis-icon--mid-blue {
    fill: #07294b;
  }
.vis-icon--orange {
    fill: #ff9e2c;
  }
.vis-icon--red {
    fill: #b94141;
  }
.vis-icon--green {
    fill: #556b2f;
  }
.vis-icon--blue {
    fill: #008b8b;
  }
.vis-icon--dark {
    fill: #333;
  }
/* ///////////////  */
/* grid */
.vis-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
}
@media (min-width: 800px) {
.vis-grid {
    /* @media (min-width: 1200px) { */
    grid-template-columns: repeat(2, 1fr);
}
  }
.vis-grid > article {
    position: relative;
    top: 50%;
    text-align: center;
    transform: translate(0, -50%);
    padding: 6rem 2rem;
  }
.vis-grid__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }
.vis-grid__box-2 {
    background-color: #ff9e2c;
  }
.vis-grid__box-1 {
    /* background-color: #b6701e; */
    /* background-color: #07294b; */
    background-color: #b94141;
  }
.vis-grid__box-3 {
    background: #556b2f;
  }
.vis-grid__box-4 {
    background-color: #008b8b;
  }
ul.vis {
  list-style: none !important;
  font-size: 2.4rem;
}
/*  btn */
.vbtn {
  display: inline-block !important; /*   as this has padding  */
  text-decoration: none;
  /* margin-top: 3.2px; */
  border-radius: 4px;
  /* overflow: hidden; */
  text-decoration: none;
  color: var(--white);
  font-size: 1.7rem;
  padding: 0.8rem 1.2rem !important;
  border: none;
  outline: none;
  background: #000;
}
.vbtn--orange {
    background: var(--secondary) !important;
    color: white !important;
  }
.vbtn--orange:hover {
      background: var(--secondary-dark) !important;
    }
.vbtn--red {
    background: #b94141;
  }
.vbtn--red:hover {
      background: #be1212 !important;
    }
.vbtn--white {
    background: var(--white);
    color: var(--secondary);
  }
.vbtn--white:hover {
      background: #ddd !important;
      color: var(--secondary);
    }
.vbtn--large {
    font-size: 1.8rem;
    padding: 1.4rem 1.2rem;
  }
/* padding: 1.1rem 1.9rem; */
@media (min-width: 530px) {
.vbtn--large {
    /* @media (min-width: 620px) {  */
      font-size: 2.1rem
      /* font-size: 1.25rem; */
  }
  }
hr.blue {
  border: 3px solid #2f5572;

  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}
.divider {
  padding: 4rem 0;
}
.vis-page-section {
  background-color: #0c171f;
  color: white;
}
.vis-border {
  border: 2px solid #2f5572;
  border-radius: 10px;
}
.vis-bg {
  /* background-color: #0c171f !important; */
  background-color: #2f5572 !important;
  margin-bottom: 20px;
  color: white;
}
.tech-logos {
  display: flex;
  flex-wrap: wrap;
  /* background-color: rgb(185, 182, 182); */
  background-color: white;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
}
.tech-logos--graphics {
    opacity: 0.7;
    padding-top: 6rem;
  }
.tech-logo {
  width: 150px;
}
.tech-logo-graph {
  width: 100px;
}
/* ///////////////////  */
/*      Site Header     */
.site-header {
  padding: 8rem 0;
  position: absolute;
  width: 100%;
  z-index: 2;
  transition: background-color 0.3s ease-out;
}
.site-header--is-expanded {
    background-color: rgba(255, 255, 255, 0.9);
  }
.site-header__menu-icon {
    cursor: pointer;
  }
@media (min-width: 800px) {
.site-header {
    /* @media (min-width: 1200px) { */
    padding: 2.6rem 0;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6);
  }

    .site-header--dark {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
@media (min-width: 1250px) {
.site-header {
    padding: 3.62rem 0;
  }
  }
@media (min-width: 800px) {
.site-header__btn-container {
    /* @media (min-width: 1200px) { */
      float: right
  }
  }
.site-header__logo {
    position: absolute;
    top: 0;
    left: 50%;
    transition: transform 0.3s ease-out;
    transform: translateX(-50%) scale(0.8);
    transform-origin: 50% 0;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 25px 10px;
    width: 35rem;
  }
@media (min-width: 800px) {
.site-header__logo {
    /* @media (min-width: 1200px) { */
      left: auto;
      transform: translateX(0) scale(0.8)

      /* shrink the logo on scroll down */
  }
      .site-header--dark .site-header__logo {
        transform: scale(0.5);
      }
  }
@media (min-width: 1250px) {
.site-header__logo {
      left: auto;
      transform: translateX(0) scale(1)

      /* shrink the logo on scroll down */
  }
      .site-header--dark .site-header__logo {
        transform: scale(0.59);
      }
  }
.site-header__logo__graphic {
    text-indent: -9999px;
  }
.site-header__menu-icon {
    cursor: pointer;
    width: 20px;
    height: 19px;
    position: absolute;
    z-index: 10; /* this enables the icon to be clicked when covered over */
    top: 12px;
    right: 12px;
  }
.site-header__menu-icon::before {
      content: '';
      position: absolute;
      /* top: 0; */
      top: 5px;
      left: -10px;
      /* left: 0; */
      /* width: 20px; */
      width: 25px;
      height: 3px;
      /* background-color: #b36531; */
      background-color: white;
      transform-origin: 0 0;
      transition: transform 0.3s ease-out;
    }
.site-header__menu-icon__middle {
      position: absolute;
      top: 13px;
      /* top: 8px; */
      left: -10px;
      /* left: 0; */
      width: 25px;
      /* width: 20px; */
      height: 3px;
      /* background-color: #b36531; */
      background-color: white;
      transition: all 0.3s ease-out;
      transform-origin: 0 50%;
    }
.site-header__menu-icon::after {
      content: '';
      position: absolute;
      bottom: -5px;
      /* bottom: 0; */
      left: -10px;
      /* left: 0; */
      width: 25px;
      /* width: 20px; */
      height: 3px;
      background-color: white;
      transform-origin: 0 100%;
      transition: transform 0.3s ease-out;
    }
@media (min-width: 800px) {
.site-header__menu-icon {
    /* @media (min-width: 1200px) { */
      display: none;
  }
  }
.site-header__menu-icon--close-x::before {
      transform: rotate(36deg) scaleX(1.25);
      /* transform: rotate(45deg) scaleX(1.25); */
      background-color: #b36531;
    }
.site-header__menu-icon--close-x .site-header__menu-icon__middle {
      opacity: 0;
      transform: scaleX(0);
    }
.site-header__menu-icon--close-x::after {
      transform: rotate(-36deg) scaleX(1.25) translateY(1px);
      /* transform: rotate(-45deg) scaleX(1.25) translateY(1px); */
      background-color: #b36531;
    }
.site-header__menu-content {
    font-family: 'Westbake', sans-serif;
    opacity: 0;
    transform: scale(1.2);
    transition: all 0.3s ease-out; /* opacity zindex scale*/
    position: relative;
    /* z-index: 10; */
    margin-top: 4rem;
    padding-top: 10rem;
    padding-bottom: 10rem;
    text-align: center;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
@media (min-width: 800px) {
.site-header__menu-content {
    /* @media (min-width: 1200px) { */
      margin-top: 0;
      opacity: 1;
      z-index: 1;
      padding-top: 0;
      padding-bottom: 0;
      transform: scale(1);
      justify-content: right;
  }
  }
.site-header__menu-content--is-visible {
      opacity: 1;
      z-index: 1;
      transform: scale(1);
    }
.site-header ul {
    margin: 0;
    padding: 0;
    /* @mixin clearfix; */
  }
.site-header li {
    list-style: none;
    display: block;
  }
@media (min-width: 800px) {
.site-header li {
    /* @media (min-width: 1200px) { */
      display: inline-block
  }
  }
.site-header a {
    color: var(--primary);
    font-weight: 300;
    text-decoration: none;
    display: block;
    padding: 15px 8px;
    font-size: 2.8rem;
    background-color: rgba(var(--primary), 0.5);
  }
@media (min-width: 800px) {
.site-header a {
    /* @media (min-width: 1200px) { */
      padding: 12px 0;
      font-size: 2rem;
      background-color: transparent
  }

      .site-header a.is-current-link {
        color: var(--secondary);
      }
  }
/* /////////////////////////// */
/* ///////  Large Hero  ////// */
/* /////////////////////////// */
.large-hero {
  border-bottom: 10px solid var(--primary);
  position: relative;
}
.large-hero__image {
    opacity: 0.85;
    height: 80rem;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
@media (min-width: 530px) {
.large-hero__image {
    /* @media (min-width: 620px) {  */
      height: 70rem;
      width: 100%
  }
  }
@media (min-width: 800px) {
.large-hero__image {
    /* @media (min-width: 1200px) { */
      height: 80rem;
      width: 100%
  }
  }
.large-hero__text-content {
    padding-top: 160px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    text-align: center;
  }
.large-hero__title {
    font-weight: 300;
    color: var(--primary);
    margin: 0;
    font-size: 4.2rem;
    text-shadow: 9px 7px 10px #3b3b3b;
  }
@media (min-width: 530px) {
.large-hero__title {
    /* @media (min-width: 620px) {  */
      font-size: 4.5rem
  }
  }
@media (min-width: 800px) {
.large-hero__title {
    /* @media (min-width: 1200px) { */
      font-size: 5.5rem
  }
  }
.large-hero__title--white {
    color: white;
    font-family: 'Westbake', sans-serif;
  }
.large-hero__description {
    font-weight: 300;
    color: var(--primary);
    font-size: 2.4rem;
    margin: 0;
  }
@media (min-width: 530px) {
.large-hero__description {
    /* @media (min-width: 620px) {  */
      font-size: 3rem;
  }
  }
.large-hero__description--white {
      color: white;
    }
.large-hero__subtitle {
    color: #fff;
    font-size: 2.1rem;
    font-weight: 100;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    max-width: 42rem;
    margin-left: auto;
    margin-right: auto;
  }
@media (min-width: 530px) {
.large-hero__subtitle {
    /* @media (min-width: 620px) {  */
      font-size: 3rem;
      max-width: 48rem
  }
  }
.large-hero__description--text-main-color {
    color: var(--primary);
  }
/* Hero bars around title */
.box-br {
  --b: 5px;
  /* thickness of the border */
  --c: #de4e0f;
  /* color of the border */
  --w: 40%;
  /* width of border */

  border: var(--b) solid transparent;
  /* space for the border */
  --g: #0000 90deg, var(--c) 0;
  background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--g))
      0 0,
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--g)) 100%
      100%;
  background-size: var(--w) var(--w);
  background-origin: border-box;
  background-repeat: no-repeat;

  /*box content code*/
  line-height: 80px;
  width: 340px;
  height: 170px;
  box-sizing: border-box;
  margin-bottom: 150px;
  display: inline-flex;
  flex-direction: column;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}
@media (min-width: 530px) {
.box-br {
    /* @media (min-width: 620px) {  */
    --b: 8px;
    --c: #de4e0f;
    --w: 40%;

    border: var(--b) solid transparent;
    /* space for the border */
    --g: #0000 90deg, var(--c) 0;
    background: conic-gradient(
          from 90deg at top var(--b) left var(--b),
          var(--g)
        )
        0 0,
      conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--g))
        100% 100%;
    background-size: var(--w) var(--w);
    background-origin: border-box;
    background-repeat: no-repeat;

    /*box content code*/
    width: 420px;
    height: 210px;
    margin-bottom: 130px;
    font-size: 25px;
    background-color: rgba(0, 0, 0, 0.1)
    /* background-color: green; */
}
  }
/* //// */
@media (min-width: 800px) {
.box-br {
    /* @media (min-width: 1200px) { */
    --b: 12px;
    --c: #de4e0f;
    --w: 40%;

    border: var(--b) solid transparent;
    /* space for the border */
    --g: #0000 90deg, var(--c) 0;
    background: conic-gradient(
          from 90deg at top var(--b) left var(--b),
          var(--g)
        )
        0 0,
      conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--g))
        100% 100%;
    background-size: var(--w) var(--w);
    background-origin: border-box;
    background-repeat: no-repeat;

    /*box content code*/
    width: 600px;
    height: 300px;
    margin-bottom: 120px;
    font-size: 25px;
    background-color: rgba(0, 0, 0, 0.1)
    /* background-color: red; */
}
  }
/* /////// */
/* pensils */
.pensils {
  opacity: 0.8;
  height: 35rem;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
/* ////////////////  */
/*   our services */
.v-hero {
  width: 100%;
  max-width: 1236px;
  margin: 0 auto;
  border-bottom: 10px solid grey;
  display: grid;
  /* grid-template-columns: 10rem 1fr 10rem; */
  grid-template-columns: 2% 1fr 2%;
  grid-template-rows: 1fr;
}
/* gap: 1rem; */
.v-hero__image {
    opacity: 0.8;
    grid-column: 1 / 4;
    grid-row: 1 / 2;
  }
.v-hero__image img {
      display: block;
      width: 100%;
      height: 1100px;
      -o-object-fit: cover;
         object-fit: cover;
    }
@media (min-width: 800px) {
.v-hero__image img {
    /* @media (min-width: 1200px) { */
        height: 700px
    }
  }
.v-hero__text-content-t {
    opacity: 1;
    /* align-self: center;
    text-align: center; */
    margin: 5rem 0;
    color: var(--white);
  }
@media (min-width: 800px) {
.v-hero__text-content-t {
    /* @media (min-width: 1200px) { */
      margin: 1rem 0
  }
  }
.v-hero__text-content-c {
    opacity: 0.85;
    grid-column: 2 / 3;
    grid-row: 1/2;
    align-self: center;
    /* padding-top: 60px; */
    text-align: center;
    /* position: absolute; top: 50%;  transform: translateY(-50%);  left: 0;   width: 100%; */
  }
.v-hero__text-content-c--orange {
    background-color: #b45312;
    padding: 2rem 0;
    /* gap: 4rem; */
    border-radius: 4rem;
    display: grid;

    grid-template-columns: 1fr;
    /* justify-content: start; */
    text-align: center;
    justify-content: center;
  }
@media (min-width: 530px) {
.v-hero__text-content-c--orange {
    /* @media (min-width: 620px) {  */
      gap: 0.6rem;
  }
  }
@media (min-width: 800px) {
.v-hero__text-content-c--orange {
    /* @media (min-width: 1200px) { */
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
  }
  }
@media (min-width: 800px) {
.v-hero__text-content-c--orange > :nth-child(1) {
    /* @media (min-width: 1200px) { */
        padding-left: 4rem
    }
  }
.v-hero__border-right {
    position: relative;
  }
.v-hero__border-right::before {
    content: '';
    border-bottom: 1px solid white;
    position: absolute;
    left: 10%;
    bottom: 0;
    right: 10%;
  }
@media (min-width: 800px) {
.v-hero__border-right::before {
    /* @media (min-width: 1200px) { */
      /* width: 50%; */
      content: '';
      border-bottom: 0;
      border-right: 2px solid white;
      position: absolute;
      top: 10%;
      bottom: 10%;
      right: 0
  }
  }
.v-hero__micro-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
.v-hero__micro-grid__title,
    .v-hero__micro-grid__icon {
      grid-column: 1 / -1;
      justify-self: center;
    }
.v-hero__micro-grid__subtitle {
      justify-self: center;
    }
@media (min-width: 800px) {
.v-hero__micro-grid {
    /* @media (min-width: 1200px) { */
      grid-template-columns: 1fr;
      margin-bottom: 1rem;
      padding-bottom: 2rem;
  }

      .v-hero__micro-grid__title,
      .v-hero__micro-grid__icon,
      .v-hero__micro-grid__subtitle {
        grid-column: 1;
        justify-self: center;
      }
  }
.v-hero__icon {
    width: 10rem;
    /* height: 8.5rem; */
    aspect-ratio: 1;
  }
@media (min-width: 800px) {
.v-hero__icon {
    /* @media (min-width: 1200px) { */
      width: 8.5rem
  }
  }
.v-hero__title {
    font-weight: 400;
    color: var(--white);
    font-size: 2.4rem;
    max-width: 42rem;
  }
@media (min-width: 800px) {
.v-hero__title {
    /* @media (min-width: 1200px) { */
      font-size: 2.3rem
  }
  }
.v-hero__subtitle {
    font-weight: 100;
    color: var(--white) !important;
    font-size: 2.1rem !important;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    max-width: 42rem;
    /* margin: 0 auto; */
  }
.v-hero__description {
    font-weight: 300;
    color: var(--white);
    font-size: 2.4rem;
    margin: 0;
  }
.v-hero__description--text-main-color {
    color: var(--white);
  }
.v-hero.v-hero--border-top {
    /* border-top: 10px solid var(--primary); */
    border-top: 10px solid grey;
  }
.contact {
  overflow: hidden;
  padding-left: 4rem;
  padding-right: 4rem;
  max-width: 1236px;
  margin-left: auto;
  margin-right: auto;
  /* font-family: 'Westbake', sans-serif; */
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.contact__top {
    /* background: var(--secondary); */
    color: #0c171f;
    padding: 1rem;
    text-align: center;
    font-size: 3rem;
    margin-bottom: 3rem;
  }
.contact__top > img {
      width: 30rem;
    }
.contact__bottom {
    background-color: var(--secondary);
    padding: 2rem;
    gap: 4rem;
    border-radius: 1rem;
    color: var(--white);
    letter-spacing: 2px;
    text-align: center;
    max-width: 700px;
    margin: 0 auto 2rem auto;
  }
@media (min-width: 530px) {
.contact__bottom {
    /* @media (min-width: 620px) {  */
      border-radius: 4rem
  }
  }
/* ///////////  */
/*  FORM */
.vis__form {
  /* margin: 0 2rem; */
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 1rem;
  padding: 0 2rem;
}
@media (min-width: 800px) {
.vis__form {
    /* @media (min-width: 1200px) { */
    grid-template-columns: auto 1fr
}
  }
.vis__form label {
  font-size: 1.7rem;
  font-weight: 500;
  display: none;
}
@media (min-width: 800px) {
.vis__form label {
    /* @media (min-width: 1200px) { */
    display: block
}
  }
.vis__form input,
.vis__form textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  /* border: none; */
  /* outline: none; */
  font-size: 1.7rem;
  padding: 1rem 1.5rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;

  resize: none;
}
.vis__form input::-moz-placeholder, .vis__form textarea::-moz-placeholder {
    color: #aaa;
  }
.vis__form input::placeholder, .vis__form textarea::placeholder {
    color: #aaa;
  }
.vis__form button {
  justify-self: center;
  margin-top: 1rem;
}
@media (min-width: 800px) {
.vis__form button {
    /* @media (min-width: 1200px) { */
    grid-column: 1 / span 2
}
  }
.vis-btn {
  display: inline-block;
  background-color: var(--secondary);
  font-size: 1.6rem;
  font-family: inherit;
  font-weight: 500;
  border: none;
  padding: 1.25rem 4.5rem;
  border-radius: 10rem;
  cursor: pointer;
  transition: all 0.3s;
  color: white;
}
.vis-btn:hover {
  background-color: var(--secondary-dark);
}
/*  Cuts */
.cuts {
  /* padding: 0 0 0 0; */
  background: #f5f3f5;
}
.cuts--center {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}
/* padding:  0 2rem 1rem 2rem; */
@media (min-width: 530px) {
.cuts--center {
    /* @media (min-width: 620px) {  */
    grid-template-columns: 1fr 1fr;
    /* padding:  0 4rem 1rem 4rem; */
}
  }
/* @mixin atLarge  {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }  */
.cuts--center--no-margin {
    grid-gap: 1rem;
  }
@media (min-width: 530px) {
.cuts--center--no-margin {
    /* @media (min-width: 620px) {  */
      grid-gap: 0
  }
  }
.cuts__cut {
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
  overflow: hidden;
  position: relative;
}
.cuts__img {
  opacity: 0.8;
}
@media (min-width: 530px) {
    /* @media (min-width: 620px) {  */
  .cuts__img {
    opacity: 1;
    width: 100%;
    display: block;
    transition: opacity 1s ease, transform 1s ease;
  }

  .cuts__img:hover {
    opacity: 0.3;
    cursor: pointer;
    transform: scale(1.3);
  }
  }
.cuts__caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--secondary);
  text-transform: uppercase;
  font-size: 4.5rem;
  /* font-weight: bold ; */
  text-align: center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  pointer-events: none; /* prevent this class interfering with the hover effect */
  background-color: #00000025;
}
@media (min-width: 530px) {
.cuts__caption {
    /* @media (min-width: 620px) {  */
    font-size: 2.5rem;
    font-weight: lighter;
    opacity: 0;
    transition: all 0.7s;
    color: var(--white);
    transform: translate(-50%, 80%)
}
  }
@media (min-width: 530px) {
    /* @media (min-width: 620px) {  */
  .cuts__cut:hover .cuts__caption {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  }
/* ----------------- */
/*  Pricing */
.pricing {
  margin-top: 2rem;
  display: grid;
  grid-gap: 20px;
  /*grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));*/
  grid-template-columns: 1fr;
  font-family: 'Oxygen', sans-serif;
}
.pricing__box {
  /*text-align: center;*/
  padding: 1.5rem 2rem;
  /*text-align: left;*/
  /*box-shadow: var(--shadow);*/
  background: white;
  /*background: #fff;*/
  display: grid;
}
.pricing__box h3 {
  padding: 10px;
  display: block;
  background: white;
  color: var(--blue);
  text-transform: capitalize;
  /*font-style: italic;*/
  font-style: normal;
  font-size: 2.2rem;
  text-align: center;
}
@media screen and (min-width: 776px) {
  .pricing {
    grid-template-columns: 1fr 1fr;
  }
  .pricing__box p {
    font-size: 1.8rem;
  }
  .pricing__box h3 {
    font-size: 2.2rem;
  }
}
.pricing__box p {
  padding: 5px;
  display: block;
  background: white;
  color: var(--blue);
  text-transform: capitalize;
  /*font-style: italic;*/
  font-style: normal;
  font-size: 1.6rem !important;
  /*border: 2px solid var(--gold);*/
  text-align: left;
}
.pricing__box img {
  width: 80%;
  justify-self: center;
}
.pricing__underline {
  /*border-bottom: 1px solid #000;*/
  border-bottom: 1px solid var(--primary);
  width: 100%;
  display: inline-block;
  line-height: 2;
}
.pricing__nounderline {
  border-bottom: 1px solid #fff;
}
.jen-break {
  /* font-family: arial, helvetica, sans-serif; */
  font-family: Cambria, Cochin, Georgia, 'Times New Roman', Times, serif;
  height: 15vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*margin: 5px 0 0 0;*/
}
.jen-break--paisley {
  background-image: url(assets/images/paisley.png);
}
/* @import 'modules/_rjm'; */
/* @import 'chairs/_chairs'; */
/* /////////////////////////// */
/* Green over blue OR Omnifood */
/* /////////////////////////// */
/* @import 'omnifood/_omnifood'; */
/* @import 'omnifood/_omnifood_general'; */
/* 
@import 'greenOverBlue/_gobHome';
@import 'greenOverBlue/_gobHeader';
@import 'greenOverBlue/_gobNavigation';
@import 'greenOverBlue/_gobHeroSlider';
@import 'greenOverBlue/_gobCarousel.css'; */
/* gob2 */
/* @import 'greenOverBlue/_gb.css'; */
/* ////////////////////////////////// */
/* West Hill Music */
/* @import 'westHill/_westHill.css'; */
/* ///////////////////////////////// */
/* @import 'portfolio/_grids.css'; */
/* 
@import 'natours/components/_composition';
@import 'natours/components/_feature-box';
@import 'natours/components/_card';
@import 'natours/components/_story';
@import 'natours/components/_heading';
@import 'natours/components/_form';
@import 'natours/layout/_footer';
@import 'natours/components/_popup';
@import 'natours/layout/_header';
@import 'natours/layout/_navigation';
@import 'natours/pages/_home';

/* /////////////////////////////  */
/* /////////  Busy Bee  ///////    */
/* ///// @import 'busyBee/_base'; */
/* @import 'busyBee/_base.scss';
@import 'busyBee/_components.scss';
@import 'busyBee/_header.scss';
@import 'busyBee/_preview.scss';
@import 'busyBee/_searchResults.scss';
@import 'busyBee/_recipe.scss';
@import 'busyBee/_upload.scss'; */
/*////////  Bankist //////// */
/* @import 'bankist/_bankist'; */

